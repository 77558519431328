import {api, graph2, graph_vars, graph_vars_str} from '../utils/shared'

export const baskets = async(token, fields, vars=[]) => {

    let p = graph_vars_str(vars)

    let vparams = p[0]
    let qparams = p[1]
    let j_params = p[2]

    let q = `query ${vparams} {
            agent_sessions ${qparams}
            {
                result
                    {
                        ${fields}
                    }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`

    let x = await graph_vars(q, j_params, token)
    return x
}

export const basket_from_reference = async(token, fields, reference) => {

    let vars = []
    vars.push({"param_var_name": "$reference", "param_name": "reference", "param_type": "String!", "value": reference})
    let p = graph_vars_str(vars)

    let vparams = p[0]
    let qparams = p[1]
    let j_params = p[2]

    let q = `query ${vparams} {
            agent_session_from_reference ${qparams}
            {
                result
                    {
                        ${fields}
                    }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`

    let x = await graph_vars(q, j_params, token)
    return x
}

export const session_rooms_reference = async(reference, fields, token) => {
    let q = `{
        agent_session_rooms_reference(reference: "${reference}")
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}

export const session_flight_reference = async(product_id, reference, fields, token) => {
    let q = `{
        agent_session_flight_reference(product_id: ${product_id}, reference: "${reference}")
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}

export const session_flight_extras_reference = async(reference, fields, token) => {
    let q = `{
        agent_session_flight_extras_reference(reference: "${reference}")
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}

export const session_transfer_reference = async(product_id, reference, fields, token) => {
    let q = `{
        agent_session_transfer_reference(product_id: ${product_id}, reference: "${reference}")
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}

export const session_package_reference = async(reference, fields, token) => {
    let q = `{
        agent_session_package_reference(reference: "${reference}")
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}
