import {api, graph2, graph_vars, graph_vars_str} from '../utils/shared'

export const bookings = async(token, fields, vars=[]) => {

    let vparams = ""
    let qparams = ""
    let j_params = {}
    if(vars.length != 0){
        let v = []
        let p = []
        for(let x of vars){
            v.push(x["param_var_name"] + ": " + x["param_type"]);
            p.push(x["param_name"] + ": " + x["param_var_name"]);
            j_params[x["param_name"]] = x["value"]
        }
        vparams = `(${v.join(" ")})`
        qparams = `(${p.join(" ")})`
    }

    let q = `query agent_bookings ${vparams} {
            agent_bookings ${qparams}
            {
                result
                    {
                        ${fields}
                    }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`

    let x = await graph_vars(q, j_params, token)
    return x
}

export const booking = async(booking_id, fields, token) => {
    let q = `{agent_booking (id: ${booking_id}) {
		result {
            ${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}

export const booking_package = async(booking_id, fields, token) => {
    let q = `{
        agent_booking_package(booking_id: ${booking_id})
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}

export const booking_room = async(product_id, booking_id, fields, token) => {
    let q = `{
        agent_booking_room(product_id: ${product_id}, booking_id: ${booking_id})
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}

export const booking_rooms = async(booking_id, fields, token) => {
    let q = `{
        agent_booking_rooms(booking_id: ${booking_id})
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}

export const booking_flight = async(product_id, booking_id, fields, token) => {
    let q = `{
        agent_booking_flight(product_id: ${product_id}, booking_id: ${booking_id})
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}

export const booking_flight_extras = async(booking_id, fields, token) => {
    let q = `{
        agent_booking_flight_extras(booking_id: ${booking_id})
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}

export const booking_transfer = async(product_id, booking_id, fields, token) => {
    let q = `{
        agent_booking_transfer(product_id: ${product_id}, booking_id: ${booking_id})
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}

export const booking_payment_plan = async(booking_id, fields, token) => {
    let q = `{
        booking_payment_plan(booking_id: ${booking_id})
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}

export const add_booking_note = async(booking_id, note, fields, token) => {
    let q = `mutation ($booking_id: Int!, $note: String!) {
            agent_booking_add_note(booking_id: $booking_id, note: $note)
            {
                result
                {
                    ${fields}
                }
            error {
                ... on Unexpected_Error {
                    message
                }
                ... on Access_Error {
                    message 
                }
            }
        }
        }`
    let x = await graph_vars(q, {"booking_id": booking_id, "note": note}, token)
    return x
}

export const booking_update_confirmed = async(booking_id, confirmed, token) => {
    let q = `mutation ($booking_id: Int!, $confirmed: Int!) {
        booking_update_confirmed(booking_id: $booking_id, confirmed: $confirmed)
            {
                result
                {
                    confirmed
                }
            error {
                ... on Unexpected_Error {
                    message
                }
                ... on Access_Error {
                    message 
                }
            }
        }
        }`
    let x = await graph_vars(q, {"booking_id": booking_id, "confirmed": confirmed}, token)
    return x
}