import React, {useEffect, useState} from 'react';
import {baskets} from '../../service/basket'
import YMDPicker from '../../components/ymd_picker'
import dayjs from 'dayjs';

const Basket = ({user_token}) => {

    const [d_view, setDataView] = useState(null);

	const [basket_ref, setBasketRef] = useState(null)

	const [email, setEmail] = useState(true)

	const [emailSet, setEmailSet] = useState(true)

	const [prodType, setProdType] = useState(null)

	const [selected_from_date, setSelectedFromDate] = useState(null)
	const [selected_to_date, setSelectedToDate] = useState(null)

	useEffect(() =>{

		let f = "id created reference email_address lead_name contact_number cost booked type"

		let filters = []
		filters.push({"param_var_name": "$has_email", "param_name": "has_email", "param_type": "Int", "value": 1})

		baskets(user_token, f, filters).then((r) =>{
			basket_result(r);
		});
    }, [])
	
	const filter = () => {
		let filters = []
		if(basket_ref != null && basket_ref != ''){
			filters.push({"param_var_name": "$reference", "param_name": "reference", "param_type": "String", "value": basket_ref})
		}
		
		if(selected_from_date != null){

			let from_date = selected_from_date + ' 00:00:00';
			let to_date = selected_to_date;
			if(to_date == null){
				let today_m = dayjs()
				to_date = today_m.format('YYYY-MM-DD')
			}
			to_date = to_date + ' 23:59:59';

			let v_date = from_date + " BETWEEN " + to_date;
			filters.push({"param_var_name": "$created_range", "param_name": "created_range", "param_type": "String!", "value": v_date})
		}

		if(email != null){
			if(email){

				setEmailSet(true);
				filters.push({"param_var_name": "$has_email", "param_name": "has_email", "param_type": "Int", "value": 1})
			}
			else{
				setEmailSet(false);
				filters.push({"param_var_name": "$has_email", "param_name": "has_email", "param_type": "Int", "value": 0})
			}
		}
		else{
			setEmailSet(false);
		}

		if(prodType != null){
			filters.push({"param_var_name": "$type", "param_name": "type", "param_type": "Int", "value": prodType})
		}


		let f = "id created reference email_address lead_name contact_number cost booked type"
		baskets(user_token, f, filters).then((r) =>{
			basket_result(r);
		});
	}

	const reset_filters = () =>{

		setEmailSet(false);
		setBasketRef(null);
		setSelectedFromDate(null);
		setSelectedToDate(null);
		setEmail(null);
		setBasketRef(null)

		let f = "id created reference email_address lead_name contact_number cost booked type"
		baskets(user_token, f, []).then((r) =>{
			basket_result(r);
		});
	}	

	const setHasEmail = (checked) => {
		setEmail(checked);
	}

	const updateProductType = (value) => {

		if(value == ""){
			setProdType(null);
		}
		else{
			setProdType(Number(value));
		}
	}

	const basket_result = (r) => {
		if(r == null){
			return
		}	
		if(r.errors != null){
				if(r.errors.length > 0){
					alert(r.errors[0].message)
					return
				}
				alert("Unexpected Error Contact HWT support...")
				return
		}
		if(r.agent_sessions.error != null){
			alert(r.agent_sessions.error.message)
			return
		}
		setDataView(r.agent_sessions.result)
	}


	const change_from_date = (v) => {
		setSelectedFromDate(v)
	}	
	
	const change_to_date = (v) => {
		setSelectedToDate(v)
	}

	let tbl_view = null;
	if(d_view != null){

		let contactHeadRows = [];
	
		if(emailSet){
			contactHeadRows.push(<><td>Email Address</td><td>Contact Number</td><td>Lead Name</td><td>Cost</td></>)

		}
		

		let rows = []
		for(let x of d_view){

			let date_of_basket = dayjs(x.created, 'YYYY-MM-DD HH:mm');
			let str_bask_date = date_of_basket.format('DD MMMM YYYY HH:mm');

			let contactRows = [];
			let colorStyle = ''
			if(emailSet){

				let bookLbl = '-';
				if(x.booked == 1){
					bookLbl = 'Booked'
					colorStyle = '#66d188';
				}
				contactRows.push(<><td>{x.email_address}</td><td>{x.contact_number}</td><td>{x.lead_name}</td><td>{x.cost}</td></>)
			}

			let ptype = ''
			if(x.type == 0){
				ptype = 'Beach'
			}
			else if(x.type == 1){
				ptype = 'City'
			}
			else if(x.type == 2){
				ptype = 'Package'
			}
			

			rows.push(<tr style={{backgroundColor: colorStyle}}>
				<td>
					{x.reference}
				</td>
				{contactRows}
				<td>
					{ptype}
				</td>
				<td>
					{str_bask_date}
				</td>
				<td>
					<a href={'/basket/view?bref=' + x.reference}>View</a>
				</td>
			</tr>)
		}

		tbl_view = <table>
				<thead>
					<td>
						Basket Reference
					</td>
					{contactHeadRows}
					<td>
						Product Type 
					</td>
					<td>
						Created 
					</td>
					<td>
						
					</td>
				</thead>
			<tbody>
				{rows}
			</tbody>
		</table>
	}

	let str_basket_ref = ''
	if(basket_ref != null){
		str_basket_ref = basket_ref;
	}

	let chk=false;
	if(email != null){
		if(email){
			chk=true;
		}
	}


	return (
		<section className='aboutcontent-box'>
	
			<div className='container'>
				{/* <div dangerouslySetInnerHTML={{ __html: data.content }} /> */}


				<div className='fitler_sec'>
					<div className='filter_field'>
						<div>
							Basket reference
						</div>
						<div>
							<input id='txtBookingRef' value={str_basket_ref} onChange={(e) => setBasketRef(e.target.value)}></input>
						</div>
					</div>

					<div className='filter_field'>
						<div>
							Email captured
						</div>
						<div>
							<input type='checkbox' checked={chk} onChange={(e) => setHasEmail(e.target.checked)}></input>
						</div>
					</div>
					

					<div style={{height: '105px'}}>
					<div>
						<div className='filter_field' style={{marginBottom: '15px'}}>
							<div>
								Created 
							</div>
							<div>
								<YMDPicker id='sel_bk_from' key='sel_bk_from_k' initalValue={selected_from_date} onChange={(value) => change_from_date(value)}></YMDPicker> 
							</div>
						</div>
						<div className='filter_field' style={{marginBottom: '15px'}}>
							<div>
								To
							</div>
							<div>
								<YMDPicker id='sel_bk_to' key='sel_bk_to_k' initalValue={selected_to_date} onChange={(value) => change_to_date(value)}></YMDPicker>
							</div>
						</div>
					</div>
					</div>

					<div className='filter_field'>
						<div>
							Product Type
						</div>
						<div>
							<select onChange={(e) => updateProductType(e.target.value)}>
								<option value="">All</option>
								<option value="-1">Dynamic Package</option>
								<option value="0">Beach</option>
								<option value="1">City</option>
								<option value="2">Package</option>
							</select>
						</div>
					</div>

					<div className='filter_field'>
						<div>
							<button onClick={() => filter()}>Filter</button>
							<button onClick={() => reset_filters()}>Reset</button>
						</div>
					</div>

				</div>

				<div className='booking_tbl'>
					{tbl_view}
				</div>

			</div>
		</section>
	);
};

export default Basket;
