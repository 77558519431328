import React, {useEffect} from 'react';
import { Routes, Route, BrowserRouter,  } from 'react-router-dom';
import LogIn from './containers/log_in';
import Nav from './components/nav';
import { agent_log_in, client_agent } from './service/user';
import {cookie, getCookie, expire_cookie} from './utils/shared';

import Bookings from './containers/bookings'
import BookingView from './containers/booking_view';

import Basket from './containers/baskets';
import BaskeView from './containers/basket_view';

import '../src/App.css';

class App extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        user_id: 0,
        role_id: 0,
        user_logged_in: false,
        two_challenge: false,
        user_name: null,
        user_token: '',
        log_in_attempts: 0,
        log_in_err: false,
        menu_links: [{'url': '/', 'name': 'Bookings', 'new': false},{'url': '/baskets', 'name': 'Customer Reference', 'new': false}]
      };
    }

    
    componentDidMount(){
      let uli_tk = getCookie('usign',true);

      window.setInterval(() => {

        if(uli_tk != null){
          client_agent().then((d) => {
            if(d == null){
              expire_cookie("usign")
              this.setState({user_logged_in: false, user_token: ''});
              window.location = '/';
            }
            else{
              if(d.data.client_agent.result == null){
                expire_cookie("usign")
                this.setState({user_logged_in: false, user_token: ''});
                window.location = '/';
                return
              }
            }
          })
        }

      }, 30000)

      if(uli_tk != null){

        client_agent().then((d) => {
          if(d == null){
            expire_cookie("usign")
            this.setState({user_logged_in: false, user_token: ''});
            window.location = '/';
          }
          else{

            if(d.data.client_agent.result == null){
              expire_cookie("usign")
              this.setState({user_logged_in: false, user_token: ''});
              window.location = '/';
              return
            }

            let user_a_token = uli_tk['token'];
            this.setState({user_logged_in: true, user_token: user_a_token})
          }
        })     
      }
    }

    log_in = async(user_name, password) =>{
      const {log_in_attempts} = this.state;
      agent_log_in(user_name, password).then((r) => {
         let la = 0;
         if(r == null){
            la = log_in_attempts + 1;
            this.setState({user_logged_in: false, log_in_err: true});
         }
         else{

            let cd = JSON.stringify(r)
            cookie('usign', cd, false, 28);
            window.location = '/';
         }
      
      });
    }
    
    log_out = () => {
      cookie('usign', null, false, -100);
      window.location = '/';
    }


    render() {

      const {user_logged_in, log_in_attempts, log_in_err} = this.state

      let log_in_message = null;
      if(log_in_attempts > 0){
        log_in_message = <div>Username / Password incorrect</div>
      }

      if(!user_logged_in){
        return(
          <div className='app' style={{width:'100%'}}>
            <BrowserRouter>
            <Routes>
                <Route path="/" element={<LogIn {...this.props} isError={log_in_err} log_in_func={(u, p) => this.log_in(u, p)} ></LogIn>} exact />
            </Routes>
            {log_in_message}
            </BrowserRouter>
          </div>
        )
    }


    return(
        <div className='app' style={{width:'100%'}}>

<BrowserRouter>
            <Nav user_token={this.state.user_token} usr_ref={this.state.sub_user_ref} links={this.state.menu_links} log_out_func={() => this.log_out()}></Nav>

              <Routes>
                  <Route path="/" element={<Bookings user_token={this.state.user_token} authorize={(x) => this.access_auth(x)} set_menu={(x) => this.set_menu_links(x)}></Bookings>} exact />
                  <Route path="/bookings/view" element={<BookingView user_token={this.state.user_token} authorize={(x) => this.access_auth(x)} set_menu={(x) => this.set_menu_links(x)}></BookingView>} exact />
                  
                  <Route path="/baskets" element={<Basket user_token={this.state.user_token} authorize={(x) => this.access_auth(x)} set_menu={(x) => this.set_menu_links(x)}></Basket>} exact />
                  <Route path="/basket/view" element={<BaskeView user_token={this.state.user_token} authorize={(x) => this.access_auth(x)} set_menu={(x) => this.set_menu_links(x)}></BaskeView>} exact />


              </Routes>
            </BrowserRouter>

        </div>
    )

    }



}

export default App

